<template>
  <div class="app-container help-home">
    <!-- 添加模板按钮 -->
    <el-button
      type="primary"
      @click="handleAdded"
      size="small"
      style="margin-bottom: 28px"
      v-p="['externaladmin:helpCenter:CategoryCreate']"
      >+ 添加分类</el-button
    >
    <!-- 表格 -->
    <CommonTable
      :tableLoading="tableLoading"
      ref="table"
      :selection="false"
      height="auto"
      :cols="cols"
      :infoData="data"
    >
      <template #categoryName="{ scoped: row }">
        <span>{{ row.categoryName }}</span
        ><span v-if="row.isDefault === 1" class="defalutCate">默认分类</span>
      </template>
      <!-- 操作列 -->
      <el-table-column label="操作" width="350px" align="center">
        <template slot-scope="{ row }">
          <!-- 编辑分类 -->
          <span class="primary" @click="editCategory(row)" v-p="['externaladmin:helpCenter:editCategory']"
            >编辑分类</span
          >
          <el-divider direction="vertical"></el-divider>
          <span class="primary" @click="toArticleManage(row)" v-p="['externaladmin:helpCenter:toArticleManage']"
            >文章管理</span
          >
          <el-divider direction="vertical" v-if="row.isDefault === 0"></el-divider>
          <!-- 删除 -->
          <Popover @sureHandler="onDelete(row)" v-if="row.isDefault === 0">
            <template #tip>
              <p>确定删除?</p>
            </template>
            <template #reference="{ scope: loading }">
              <color-text-btn
                class="update-btn"
                :loading="loading"
                size="mini"
                type="danger"
                v-p="['externaladmin:helpCenter:Delete']"
                >删除</color-text-btn
              >
            </template>
          </Popover>
          <el-divider direction="vertical"></el-divider>

          <!-- 拖拽图标 -->
          <svg-icon icon-class="menu" class="text-primary draggable-btn" v-p="['externaladmin:helpCenter:Sort']" />
        </template>
      </el-table-column>
    </CommonTable>
    <!-- 分页器 -->
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <!-- 添加模板 -->
    <addDialog :form="form" :visible.sync="visible" :clickType="clickType" @init="init" />
  </div>
</template>

<script>
// 引入添加模板组件
import addDialog from './module/addDialog.vue'
// 引入commonTable表头
import { cols } from './module/cols'
// 引入初始化混入
import initDataMixin from '@/mixins/initData'
import { orderBy } from 'lodash'
import { helpCenterCategoryDelete, helpCenterCategoryChangeSort } from '@/api/helpcenter/helpcenter'
import Sortable from 'sortablejs'
import { sortableMethodsMixin } from '@/mixins'

export default {
  mixins: [initDataMixin, sortableMethodsMixin],
  components: { addDialog },
  props: {},
  inject: ['reload'],
  data() {
    return {
      // 获取列表路径
      url: '/externaladmin/systemService/helpCenterCategoryList',
      // 表格表头cols
      cols,
      // 添加模板表单数据
      form: {
        categoryName: ''
      },
      // 添加分类对话框控制
      visible: false,
      clickType: '',
      loading: false,
      query: {
        orderItems: [{ asc: true, column: 'sort_number' }]
      }
    }
  },
  computed: {
    // canSort({data}){
    //   return true
    // }
  },

  methods: {
    async initCallBack() {
      await this.$nextTick()
      this.createSortable()
    },
    createSortable() {
      /**
       *改变顺序
       找到移动的位置当前拖动的项序号更新对应的位置序号
       */
      let {
        $refs: { table }
      } = this
      table = table.table
      if (table) {
        let tbody = table.$refs.bodyWrapper.getElementsByTagName('tbody')[0]
        let trs = tbody.getElementsByTagName('tr')
        trs.forEach((tr, index) => tr.setAttribute('data-id', this.data[index].sortNumber))
        this.sortable = Sortable.create(tbody, {
          handle: '.draggable-btn',
          onEnd: async (evt) => {
            try {
              this.tableLoading = true
              const { oldIndex, newIndex } = evt
              // 下移的情况
              //移动的项 oldIndex<=newIndex
              if (oldIndex <= newIndex) {
                this.data[oldIndex].sortNumber = this.data[newIndex].sortNumber
                for (let i = 0; i < this.data.length; i++) {
                  if (i <= newIndex && i !== oldIndex) {
                    // 其他项序号自减1
                    this.data[i].sortNumber = --this.data[i].sortNumber
                  }
                }
              } else {
                // 上移的情况
                let removeId = this.data[newIndex].id
                let sortNumber = this.data.filter((item) => item.id === removeId)[0].sortNumber
                // 序号替换
                console.log('上移的情况====', oldIndex, newIndex)
                this.data[oldIndex].sortNumber = sortNumber
                for (let i = 0; i < this.data.length; i++) {
                  if (i < oldIndex && i >= newIndex) {
                    this.data[i].sortNumber = ++sortNumber
                  }
                }
              }

              let list = this.data.map(({ id, sortNumber }) => ({
                id,
                sortNumber
              }))

              console.log('result==', list)
              await helpCenterCategoryChangeSort({ list })
            } catch (e) {
              console.log('err===', e)
              this.$message.error('移动分类失败')
            } finally {
              this.init()
            }
          }
        })
      }
    },

    // 删除分类
    async onDelete(row) {
      try {
        this.loading = true
        const { code } = await helpCenterCategoryDelete({ id: row.id })
        if ($SUC({ code })) {
          this.$message.success('删除分类成功')
          this.init()
        } else {
          this.$message.error('删除分类失败')
        }
      } catch (e) {
        this.$message.error('删除分类失败')
      } finally {
        this.loading = false
      }
    },
    // 跳转至文章管理页toArticleManage
    toArticleManage(row) {
      console.log(this.$router)
      this.$router.push({
        path: '/infoManage/articleManage',
        query: {
          id: row.id,
          categoryName: row.categoryName
        }
      })
    },
    // 添加分类
    handleAdded() {
      this.visible = true
      this.clickType = 'create'
      this.form.categoryName = ''
      this.form.id = ''
    },
    // 编辑分类
    editCategory(row) {
      this.visible = true
      this.clickType = 'edit'
      this.form.categoryName = row.categoryName
      this.form.id = row.id
    }
  }
}
</script>

<style scoped lang="scss">
.help-home {
  font-size: 25px;
  // 表格内编辑分类，文章管理的样式.primary
  .color-btn-component {
    font-size: 14px;
  }
  .primary {
    color: #3841db !important;
    //margin-right: 8px;
    cursor: pointer;
  }
  .defalutCate {
    margin-left: 20px;
    color: #a1a1a1;
    font-size: 12px;
  }
}
</style>
