export const cols = [
  {
    label: '分类名称',
    prop: 'categoryName',
    minWidth: '200',
    slotName:'categoryName'
  },
  {
    // slotName: 'stateSlot',
    label: '文章数量',
    prop: 'contentCount',
    minWidth: '200'
  }
]