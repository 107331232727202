<template>
  <div class="whole-warpper">
    <!-- 对话框 -->
    <el-dialog
      title="添加分类"
      :visible.sync="visible"
      width="612px"
      :before-close="handleClose"
      class="dialog-warpper"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules" :validate-on-rule-change="false">
        <el-form-item label="分类名称：" prop="categoryName">
          <el-input
            size="mini"
            style="width: 240px"
            v-model="form.categoryName"
            placeholder="输入分类名称"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCanceled">取 消</el-button>
        <el-button size="small" :loading="loading" type="primary" @click="handleConfirmed">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { helpCenterCategoryCreate, helpCenterCategoryUpdate } from '@/api/helpcenter/helpcenter'
export default {
  props: {
    form: {
      type: Object
    },
    visible: {
      type: Boolean
    },
    clickType: {
      type: String
    }
  },
  data() {
    // 匹配中文和&符号
     const verifyCn = (rule, value, callback) => {
      if (/^[\u4e00-\u9fa5-\&\s]{0,}$/.test(value) == false) {
        callback(new Error('请输入中文'))
      } else {
        callback()
      }
      callback()
    }
     // 匹配非字母数字的其他输入
    const verifyCnMark = (rule, value, callback) => {
      if (/[A-Za-z0-9\s]+/.test(value)) {
        callback(new Error('请输入中文或符号'))
      } else {
        callback()
      }
      callback()
    }
    return {
      loading: false,
      query: {},
      rules: {
        categoryName: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
          { validator:verifyCnMark,trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // reset() {
    //   this.$emit('resetFields')
    //   // this.$refs.form.resetFields()
    // },
    handleClose() {
      this.$emit('update:visible', false)
      // this.reset()
    },
    handleCanceled() {
      this.handleClose()
      this.$refs.form.clearValidate()
      this.$message.warning('您取消了操作！')
    },
    handleConfirmed() {
      if (this.clickType === 'create') {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            try {
              this.loading = true
              const { code } = await helpCenterCategoryCreate({
                categoryName: this.form.categoryName
              })
              if ($SUC({ code })) {
                this.$message.success('添加分类成功')
                this.$emit('init')
                this.handleClose()
              } else {
                return this.$message.error('添加分类失败')
              }
            } catch (e) {
              return this.$message.error('添加分类失败')
            } finally {
              this.loading = false
            }
          } else {
            return false
          }
        })
      } else if (this.clickType === 'edit') {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            try {
              this.loading = true
              const { code } = await helpCenterCategoryUpdate({
                categoryName: this.form.categoryName,
                id: this.form.id
              })
              if (!$SUC({ code })) {
                this.loading = false
                return this.$message.error('编辑分类失败')
              } else {
                this.loading = false
                this.$message.success('编辑分类成功')
                this.$emit('init')
                this.handleClose()
              }
            } catch (e) {
              this.loading = false
              return this.$message.error('编辑分类失败')
            }
          } else {
            return false
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.whole-warpper {
  .dialog-warpper {
    ::v-deep {
      .el-form-item__label {
        color: #595961;
        font-weight: normal;
      }
      .el-radio__label {
        font-weight: normal;
      }
      .el-radio {
        margin-right: 15px;
      }
      .el-dialog__title {
        font-size: 14px;
        color: #1a1a1a;
      }
      .el-dialog__header {
        height: 52px;
        line-height: 52px;
        padding: 0 0 0 24px;
        border: 1px solid #dcdee0;
      }
      .el-input__inner {
        width: 246px;
        height: 36px;
      }
    }
  }
}
</style>
